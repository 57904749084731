<template>
  <div class="total">
    <div class="configure">
      <div class="work-title-all aligin-items-center">
        <div style="width: 212px; height: 50px">
          <p>管辖校区在读学员绑定率：<span class="color-">0.84%</span></p>
          <el-progress :show-text="false" :percentage="0.84"></el-progress>
        </div>
      </div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane disabled label="课堂点评" name="first3"> </el-tab-pane>
        <el-tab-pane label="家长申请" name="first">
          <div class="work-title bg-white">
            <div
              class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
            >
              <div
                class="work-title-content flex aligin-items-center flex-wrap-wrap"
              >
                <div class="cursor" @click="classopenDate">
                  <span>申请日期</span>
                  <i class="el-icon-caret-bottom" />
                  <span class="border-right" />
                </div>

                <el-popover width="150" trigger="click">
                  <el-input
                    v-model="shoolInput"
                    placeholder=""
                    size="mini"
                  ></el-input>

                  <el-checkbox-group
                    v-model="shool_list"
                    v-for="(item, index) in shoolList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>上课校区</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="teachersInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="courseEnrollment"
                    v-for="(item, index) in courseEnrollmentList"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>报读课程</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="applicationInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="application"
                    v-for="(item, index) in applicationStatus"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>申请状态</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
                <el-popover width="200" trigger="click">
                  <el-input
                    v-model="applyTypeInput"
                    placeholder=""
                    size="mini"
                  ></el-input>
                  <el-checkbox-group
                    v-model="applyType"
                    v-for="(item, index) in fileType"
                    :key="index"
                  >
                    <el-checkbox class="padding-xs" :label="item.label" />
                  </el-checkbox-group>
                  <div class="cursor" slot="reference">
                    <span>申请类型</span>
                    <i class="el-icon-caret-bottom" />
                    <span class="border-right" />
                  </div>
                </el-popover>
              </div>

              <div
                class="work-title-search flex flex-wrap-wrap"
                style="width: 500px"
              >
                <el-select
                  style="width: 150px"
                  v-model="screen"
                  size="medium  "
                  placeholder="班级名称"
                >
                  <el-option label="福星一班" value="1" />
                  <el-option label="福星二班" value="2" />
                  <el-option label="福星三班" value="3" />
                  <el-option label="福星四班" value="4" />
                </el-select>
                <el-input
                  style="width: 300px"
                  placeholder="请输入班级名称"
                  v-model="stuInput1"
                  class="input-with-select"
                  size="medium  "
                >
                  <el-button slot="append" icon="el-icon-search" />
                </el-input>
              </div>
            </div>

            <div
              class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap"
            >
              <div class="flex aligin-items-center flex-wrap-wrap">
                <div class="text-gray flex aligin-items-center margin-top-sm">
                  <div>申请日期：</div>
                  <!-- 日期组件 -->
                  <el-date-picker
                    ref="classdatePicker"
                    v-model="classDate1"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="mini"
                  />
                </div>
                <div
                  v-if="shool_list.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>上课校区：</div>
                  <div
                    v-for="(btn, btnIndex) in shool_list"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="delOderStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="courseEnrollment.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>报读课程：</div>
                  <div
                    v-for="(btn, btnIndex) in courseEnrollment"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="teachersStatus(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="application.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>申请状态：</div>
                  <div
                    v-for="(btn, btnIndex) in application"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="applicationDel(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="applyType.length"
                  class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
                >
                  <div>申请类型：</div>
                  <div
                    v-for="(btn, btnIndex) in applyType"
                    :key="btnIndex"
                    :class="{ 'margin-left': btnIndex > 0 }"
                  >
                    <el-button size="mini">
                      {{ btn }}
                      <i
                        class="el-icon-close"
                        @click="applyTypeDel(btnIndex)"
                      />
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px">
            <div
              style="width: 100%; height: 40px"
              class="flex justify-content-flexEnd"
            >
              <el-popover width="200" trigger="click">
                <el-input
                  v-model="teachersInput"
                  placeholder=""
                  size="mini"
                ></el-input>
                <el-checkbox-group
                  v-model="courseEnrollment"
                  v-for="(item, index) in courseEnrollmentList"
                  :key="index"
                >
                  <el-checkbox class="padding-xs" :label="item.label" />
                </el-checkbox-group>
                <div class="cursor" slot="reference">
                  <span class="displayColumns">自定义显示列</span>
                </div>
              </el-popover>
            </div>
            <div>
              <as-list :initdataList="serviceCentreList">
                <el-table-column
                  align="center"
                  prop="name"
                  label="学员姓名"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="phone"
                  label="联系方式"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="type"
                  label="申请类型"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="shool"
                  label="上课校区"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="course"
                  label="报读课程"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="className"
                  label="班级名称"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="handleName"
                  label="处理状态"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="date"
                  label="请假/补课日期"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="reason"
                  label="理由"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="feedback"
                  label="处理反馈"
                ></el-table-column>

                <el-table-column
                  align="center"
                  prop="feedbackdate"
                  label="处理日期"
                ></el-table-column>
                <el-table-column align="center" label="操作"> </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="意见反馈" name="third">
          <div style="margin-top: 10px">
            <div>
              <div class="flex aligin-items-center margin-top-sm">
                <div>反馈日期：</div>
                <!-- 日期组件 -->
                <el-date-picker
                  ref="classdatePicker"
                  v-model="feedbackDate1"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  size="mini"
                />
              </div>
            </div>
            <div
              style="width: 100%; height: 40px"
              class="flex justify-content-center"
            >
              <div class="cursor">
                <span class="query">查询</span>
              </div>
            </div>
            <div>
              <as-list :initdataList="serviceCentreList">
                <el-table-column
                  align="center"
                  prop="name"
                  label="家长姓名"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="phone"
                  label="手机号码"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="type"
                  label="关注学员"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="shool"
                  label="反馈日期"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="course"
                  label="反馈校区"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="className"
                  label="反馈内容"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="handleName"
                  label="回复内容"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="date"
                  label="处理人"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="feedbackdate"
                  label="处理日期"
                ></el-table-column>
                <el-table-column align="center" label="操作"> </el-table-column>
              </as-list>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <ClassRecord
      :Visible="editVisible && editType === 4"
      @closepop="closeEditPop"
    ></ClassRecord>
  </div>
</template>
<script>
import {
  serviceCentreList,
  courseEnrollmentList,
  fileType,
  applicationStatus,
} from "@/config/index";
import ClassRecord from "../components/ClassRecord.vue";
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
    ClassRecord,
  },
  data() {
    return {
      feedbackDate1: "", //反馈日期
      applicationStatus,
      fileType,
      courseEnrollmentList, //报读课程
      serviceCentreList,
      screen: "班级名称",
      shoolInput: "",
      teachersInput: "",
      teachersInput2: "",
      applicationInput: "",
      applyTypeInput: "",
      starInput: "",
      stuInput: "",
      stuInput1: "",
      applyType: [], //申请类型
      application: [], //申请状态
      star: [], //星级
      shool_list: [], //校区
      courseEnrollment: [], //报读课程
      editType: 1,
      classDate1: "", //班级点评上课日期
      evaluationDate: "", //评价日期
      classDate: "", //上课日期
      options1: [
        {
          value: "选项1",
          label: "一星级",
        },
        {
          value: "选项2",
          label: "二星级",
        },
        {
          value: "选项3",
          label: "三星级",
        },
        {
          value: "选项4",
          label: "四星级",
        },
        {
          value: "选项5",
          label: "五星级",
        },
      ],

      options: [
        {
          value: "校区一",
          label: "校区一",
        },
        {
          value: "校区二",
          label: "校区二",
        },
        {
          value: "校区三",
          label: "校区三",
        },
      ], //校区
      value: "",
      activeName: "first",
      value1: "",
      value2: "",
    };
  },
  methods: {
    //报读课程
    teachersStatus(index) {
      this.courseEnrollment.splice(index, 1);
    },
    //校区
    delOderStatus(index) {
      this.shool_list.splice(index, 1);
    },
    //助教
    applyTypeDel(index) {
      this.applyType.splice(index, 1);
    },
    //申请状态
    applicationDel(index) {
      this.application.splice(index, 1);
    },
    starStatus(index) {
      this.starS.splice(index, 1);
    },
    openDate(t) {
      if (t) {
        console.log(t);
        this.$refs.evaluation.pickerVisible = true;
      } else {
        console.log(t);
        this.$refs.datePicker.pickerVisible = true;
      }
    },
    classopenDate(t) {
      this.$refs.classdatePicker.pickerVisible = true;
    },
    handleClick(row) {
      console.log(row);
    },
  },
};
</script>

<style lang="scss">
.total {
  width: 100%;
}
.shool {
  margin: 0px 10px;
}
.displayColumns {
  width: 60px;
  height: 30px;
  color: #fff;
  padding: 5px 10px;
  background-color: #409eff;
  border-radius: 6px;
}
.query {
  width: 80px;
  height: 30px;
  color: #fff;
  padding: 7px 30px;
  background-color: #409eff;
  border-radius: 6px;
}
// .configure {
//   width: 100%;
//   // background-color: #fff;
//   padding: 30px 10px 10px 10px ;
// }
.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}
</style>